import { PaymentOption } from 'entities/PaymentType';

import { MixpanelTracker } from 'libs/mixpanel/MixpanelTracker';

export class PaymentFlowAnalytics {
  public static onClickSeeDetails = (newValue: string) => {
    if (newValue === 'resume') {
      return MixpanelTracker.sendEvent('LINK-pagto_cliente_esconder_detalhes');
    }
    return MixpanelTracker.sendEvent('LINK-pagto_cliente_ver_detalhes');
  };

  public static onClickSeeTerms = () => {
    MixpanelTracker.sendEvent('LINK-pagto_cliente_termos_de_uso');
  };

  public static onClickPaymentType = (type: string) => {
    if (type === PaymentOption.CreditCard) {
      MixpanelTracker.sendEvent('LINK-pagto_cliente_selecionar_cartao');
    }
    if (type === PaymentOption.Pix) {
      MixpanelTracker.sendEvent('LINK-pagto_cliente_selecionar_pix');
    }
    if (type === PaymentOption.BankSlip) {
      MixpanelTracker.sendEvent('LINK-pagto_cliente_selecionar_boleto');
    }
  };

  public static onClickCardType = (cardType: string) => {
    if (cardType === PaymentOption.CreditCard) {
      MixpanelTracker.sendEvent('LINK-pagto_cliente_selecionar_crédito');
    }
    if (cardType === PaymentOption.DebitCard) {
      MixpanelTracker.sendEvent('LINK-pagto_cliente_selecionar_débito');
    }
  };

  public static onClickInstallments = (installments: number) => {
    MixpanelTracker.sendEvent('LINK-pagto_cliente_selecionar_parcelas', {
      installments,
    });
  };

  public static onClickPayment = () => {
    MixpanelTracker.sendEvent('LINK-pagto_cliente_selecionar_pagar');
  };

  public static onClickCopyCode = () => {
    MixpanelTracker.sendEvent('LINK-pagto_cliente_copiar_codigo');
  };

  public static onClickPrint = () => {
    MixpanelTracker.sendEvent('LINK-pagto_cliente_imprimir_comprovante');
  };

  public static onApprovedPayment = (type: PaymentOption) => {
    MixpanelTracker.sendEvent('LINK-pagto_cliente_pagto_aprovado');

    if (type === PaymentOption.Pix) {
      MixpanelTracker.sendEvent('LINK-pagto_cliente_aprovado_pix');
    }

    if (type === PaymentOption.BankSlip) {
      MixpanelTracker.sendEvent('LINK-pagto_cliente_aprovado_boleto');
    }

    if ([PaymentOption.CreditCard, PaymentOption.DebitCard].includes(type)) {
      MixpanelTracker.sendEvent('LINK-pagto_cliente_aprovado_cartao');
    }
  };

  public static registerPaymentProperties = (uuid?: string) => {
    MixpanelTracker.registerGlobalProperty({
      uuid,
    });
  };
}

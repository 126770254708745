import { styled, TabsTrigger, TabsContent } from '@maistodos/design-system-web';

export const PaymentButton = styled(TabsTrigger, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',

  cursor: 'pointer',

  borderStyle: 'solid',
  borderWidth: 2,
  borderColor: '$transparent',
  borderRadius: '$medium',

  backgroundColor: '$neutral0',
  fill: '$brand500',
  color: '$neutral600',
  paddingTop: '$spacing16',
  paddingBottom: '$spacing16',
  paddingLeft: '$spacing24',
  paddingRight: '$spacing24',

  fontSize: '$caption',

  '&:hover': {
    backgroundColor: '$neutral50',
  },

  '&[data-state="active"]': {
    borderColor: '$brand500',
  },
});

export const PaymentContent = styled(TabsContent, {
  paddingTop: '$spacing32',
});

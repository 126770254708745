import { styled, Typography } from '@maistodos/design-system-web';

export const SectionText = styled(Typography);

SectionText.defaultProps = {
  variant: 'medium',
  css: {
    color: '$neutral600',
  },
};

import { StrictMode } from 'react';
import './i18n';

import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Error from './pages/404';
import Payment from './pages/[id]';
import Receipt from './pages/[id]/receipt';
import Refunded from './pages/[id]/refunded';
import App from './pages/_app';
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <Error />,
      },
      {
        path: ':id/',
        element: <Payment />,
      },
      {
        path: ':id/receipt',
        element: <Receipt />,
      },
      {
        path: ':id/refunded',
        element: <Refunded />,
      },
      {
        path: ':id/*',
        element: <Error />,
      },
    ],
  },
]);

root.render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Loading, Flex } from '@maistodos/design-system-web';

export const PaymentLoading = () => {
  return (
    <Flex
      data-testid="loading"
      alignItems="center"
      justifyContent="center"
      css={{
        height: '70vh',
        '@bp1': {
          height: 500,
        },
      }}
    >
      <Loading color="$brand500" fontSize="$h1" />
    </Flex>
  );
};

/* eslint-disable import/no-named-as-default-member */
import type { Dict } from 'mixpanel-browser';
import mixpanel from 'mixpanel-browser';

import {
  IS_DEV,
  MIXPANEL_APP_ORIGIN,
  MIXPANEL_TOKEN,
} from 'config/environment';

import type { MixPanelEvents } from './types';

export class MixpanelTracker {
  private static isInitialized = false;

  protected static init() {
    if (!MIXPANEL_TOKEN) return false;

    if (!MixpanelTracker.isInitialized) {
      mixpanel.init(MIXPANEL_TOKEN, {
        debug: IS_DEV,
        loaded: () => {
          MixpanelTracker.isInitialized = true;
        },
      });
    }
    return true;
  }

  public static sendEvent(eventName: MixPanelEvents, eventProperties?: Dict) {
    const isInitialized = this.init();

    if (!isInitialized) return;

    mixpanel.track(eventName, {
      origin: MIXPANEL_APP_ORIGIN,
      ...eventProperties,
    });
  }

  public static registerGlobalProperty(properties?: Dict) {
    const isInitialized = this.init();

    if (!isInitialized) return;

    if (!properties) return;

    mixpanel.register(properties);
  }
}

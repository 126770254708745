/* eslint-disable @typescript-eslint/no-explicit-any */
import { TFunction } from 'i18next';

import { isValid, isExpirationDateValid } from 'creditcard.js';

import { Required } from './types';

export const getRequiredFields = (
  t: TFunction<'translation', undefined>
): Required => {
  return {
    number: {
      required: t('Informe o número do cartão'),
      validate: (value: any) => {
        if (isValid(value)) {
          return true;
        }

        return t('Número de cartão inválido');
      },
    },
    expDate: {
      required: t('Informe a data de validade'),
      validate: (value: any) => {
        const [month, year] = value.split('/');
        if (isExpirationDateValid(month, year)) {
          return true;
        }

        return t('Data de validade inválida');
      },
    },
    cvc: {
      required: t('Informe o código de segurança'),
      validate: (value: any) => {
        if (value.length >= 3) {
          return true;
        }

        return t('Informe um código de segurança válido');
      },
    },
    holder: {
      required: t('Informe o nome do titular'),
      validate: (value: any) => {
        if (value.trim().split(' ').length > 1) {
          return true;
        }

        return t('Informe um nome válido');
      },
    },
    installments: {
      required: t('Informe o número de parcelas'),
    },
  };
};

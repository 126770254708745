import {
  styled,
  Separator,
  Space,
  Typography,
  AccordionTrigger,
  Flex,
} from '@maistodos/design-system-web';

import { SectionGroup } from 'modules/checkout/components/Section/SectionGroup';

const SPACING: Space = '$spacing16';

export const CustomSectionGroup = styled(SectionGroup, {
  paddingLeft: SPACING,
  paddingRight: SPACING,
});

export const DetailsContainer = styled('div', {
  paddingTop: SPACING,
  paddingBottom: SPACING,

  backgroundColor: '$neutral100',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: '$neutral300',
  borderRadius: '$medium',
});

export const CustomSeparator = styled(Separator, {
  variants: {
    position: {
      top: {
        marginTop: SPACING,
      },
      bottom: {
        marginBottom: SPACING,
      },
    },
  },
});

export const Price = styled(Flex, {
  paddingLeft: SPACING,
  paddingRight: SPACING,
});

export const PriceTypography = styled(Typography);
PriceTypography.defaultProps = {
  variant: 'h6',
  weight: 'bold',
  css: {
    color: '$neutral600',
  },
};

export const AccordionTriggerSeeMore = styled(AccordionTrigger, {
  transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  padding: '$spacing16',
  width: '100%',
  border: 0,

  fontWeight: '$bold',
  fontSize: '$medium',
  color: '$neutral600',
  backgroundColor: 'transparent',

  // Closed
  svg: {
    transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
    transform: 'rotate(270deg)',
  },

  // Opened
  '[data-state=open] & svg': { transform: 'rotate(90deg)' },

  '&:hover': {
    backgroundColor: '$neutral200',
  },
});

export const AccordionContentSeeMore = styled(Flex, {
  padding: SPACING,
  gridRowGap: '$spacing8',
});

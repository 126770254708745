import { styled, Typography } from '@maistodos/design-system-web';

export const SectionTitle = styled(Typography);

SectionTitle.defaultProps = {
  variant: 'large',
  weight: 'bold',
  css: {
    color: '$neutral600',
  },
};

import { styled, keyframes } from '@maistodos/design-system-web';

const fadeIn = keyframes({
  '0%': { opacity: 0, transform: 'scale(0.9)' },
  '100%': { opacity: 1, transform: 'scale(1)' },
});

const fadeOut = keyframes({
  '0%': { opacity: 1, transform: 'scale(1)' },
  '100%': { opacity: 0, transform: 'scale(0.9)' },
});

export const Overlay = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ModalContainer = styled('div', {
  position: 'relative',
  backgroundColor: 'white',
  padding: '$spacing32',
  borderRadius: '$large',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  width: '400px',
  maxWidth: '100%',
  animation: `${fadeIn} 0.3s ease-out forwards`,
  zIndex: 10,

  variants: {
    isClosing: {
      true: {
        animation: `${fadeOut} 0.3s ease-out forwards`,
      },
    },
  },
});

export const CloseButton = styled('button', {
  position: 'absolute',
  top: '$spacing32',
  right: '$spacing32',
  background: 'transparent',
  border: 'none',
  fontSize: '$medium',
  width: '$spacing32',
  height: '$spacing32',
  borderRadius: '$circle',
  cursor: 'pointer',
  padding: '$spacing8',
  '&:hover': {
    background: '$neutral100',
    color: '$brand500',
  },
});

import { alertVariants } from 'components/Alert/constants';
import {
  AlertContainer,
  AlertContent,
  AlertMessage,
  AlertTitle,
  IconWrapper,
} from 'components/Alert/styles';
import { AlertProps } from 'components/Alert/types';

export const Alert = ({ variant = 'info', title, children }: AlertProps) => {
  const alertData = alertVariants[variant];

  return (
    <AlertContainer variant={variant}>
      <IconWrapper>
        <alertData.icon color="currentColor" fontSize="24px" />
      </IconWrapper>
      <AlertContent>
        {title ? <AlertTitle>{title}</AlertTitle> : null}
        <AlertMessage>{children}</AlertMessage>
      </AlertContent>
    </AlertContainer>
  );
};

import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Content } from 'components/Content';

import { isLanguageSupported } from 'utils/formatLocale';

import { SectionText } from 'modules/checkout/components/Section/SectionText';

import { PaymentNotFoundProps } from './types';

export const PaymentNotFound = ({ locale_code }: PaymentNotFoundProps) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(isLanguageSupported(locale_code) ?? 'pt_BR');
  }, [locale_code, i18n]);

  return (
    <Content title={t('Pagamento não encontrado')}>
      <SectionText>
        {t(
          `A página que você está tentando acessar não foi encontrada ou o link de pagamento está expirado. Verifique se o endereço está correto e tente novamente.`
        )}
      </SectionText>
    </Content>
  );
};

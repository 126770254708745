import { styled } from '@maistodos/design-system-web';

export const StyledLink = styled('a', {
  color: '$brand500',
  fontSize: '1em',
  fontWeight: '$bold',
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline',
  },
});

import { useEffect, useRef } from 'react';

import { Close } from '@maistodos/core-icons';

import {
  CloseButton,
  ModalContainer,
  Overlay,
} from 'modules/checkout/components/Modal/styles';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal = ({ isOpen, onClose, children }: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [onClose]);

  useEffect(() => {
    const handleFocus = (event: FocusEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        closeButtonRef.current?.focus();
      }
    };

    if (isOpen) {
      document.addEventListener('focus', handleFocus, true);
    }

    return () => {
      document.removeEventListener('focus', handleFocus, true);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <Overlay onClick={onClose} aria-hidden={!isOpen}>
      <ModalContainer
        ref={modalRef}
        onClick={(e) => e.stopPropagation()}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-title"
      >
        <CloseButton
          ref={closeButtonRef}
          onClick={onClose}
          aria-label="Fechar modal"
        >
          <Close color="currentColor" fontSize="1.2rem" />
        </CloseButton>
        {children}
      </ModalContainer>
    </Overlay>
  );
};

export default Modal;

import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Flex } from '@maistodos/design-system-web';

import { Content } from 'components/Content';

import { LinkStatus } from 'entities/LinkStatus';

import { MixpanelTracker } from 'libs/mixpanel/MixpanelTracker';

import { useLink } from 'services/useLink';

import { formatLocaleCustomerDocument } from 'utils/formatLocale';

import { PaymentLoading } from 'modules/checkout/components/Payment/PaymentLoading';
import { PaymentNotFound } from 'modules/checkout/components/Payment/PaymentNotFound';
import { PaymentOptions } from 'modules/checkout/components/Payment/PaymentOptions';
import { PaymentResume } from 'modules/checkout/components/Payment/PaymentResume';
import { PaymentTerms } from 'modules/checkout/components/Payment/PaymentTerms';
import { SectionGroup } from 'modules/checkout/components/Section/SectionGroup';
import { SectionText } from 'modules/checkout/components/Section/SectionText';
import { SectionTitle } from 'modules/checkout/components/Section/SectionTitle';
import { usePaymentCheck } from 'modules/checkout/hooks/usePaymentCheck';

import { PaymentProps } from './types';

export const Payment = ({ id, title }: PaymentProps) => {
  const { t, i18n } = useTranslation();
  const { data, error, isLoading } = useLink(id);
  const { isValid } = usePaymentCheck(id, LinkStatus.Created);

  useEffect(() => {
    MixpanelTracker.sendEvent('LINK-pagto_cliente_acessar_link');
  }, []);

  if (error) {
    return <PaymentNotFound locale_code={error.response?.data?.locale_code} />;
  }

  if (isLoading || !isValid || !data) {
    return (
      <Content title={title}>
        <PaymentLoading />
      </Content>
    );
  }

  return (
    <Content title={title}>
      <Flex
        direction="column"
        css={{
          position: 'relative',
          gridRowGap: '$spacing24',
        }}
      >
        {/* Customer details */}
        <SectionGroup direction="column">
          <SectionTitle>{t('Dados pessoais')}</SectionTitle>
          <SectionText
            css={{
              color: '$neutral600',
              '@bp2': {
                width: '320px',
                wordWrap: 'break-word',
              },
            }}
          >
            {data.customer.name}
          </SectionText>
          {!!data?.customer?.email && (
            <SectionText
              css={{
                '@bp2': {
                  width: '320px',
                  wordWrap: 'break-word',
                },
                color: '$neutral600',
              }}
            >
              E-mail: {data.customer.email}
            </SectionText>
          )}
          {!!data?.customer?.document && (
            <SectionText>
              {`${t('Documento')}: ${formatLocaleCustomerDocument(
                data.customer.document,
                i18n.language
              )}`}
            </SectionText>
          )}
        </SectionGroup>

        {/* Issuer details */}
        <SectionGroup direction="column">
          <SectionTitle>{t('Nome do emissor')}</SectionTitle>
          <SectionText>{data.company.name}</SectionText>
        </SectionGroup>

        {/* Mobile's Resume */}
        <Box
          css={{
            display: 'block',
            '@bp2': {
              display: 'none',
            },
          }}
        >
          <PaymentResume
            description={data.messages.main}
            amount={data.amount}
          />
        </Box>

        {/* Payment options */}
        <SectionGroup direction="column">
          <SectionTitle>{t('Forma de pagamento')}</SectionTitle>
          <PaymentOptions
            id={id}
            amount={data.amount}
            types={data.payment_types}
            installments={data.installments}
            labels={{
              defaultCard: data.accept_new_card_as_default_label,
            }}
            showSetDefaultCard={data.show_set_new_card_as_default}
          />
        </SectionGroup>

        {/* Terms */}
        <PaymentTerms
          label={data.accept_terms.label}
          file={data.accept_terms.file}
        />

        {/* Desktop's Resume */}
        <Box
          css={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: 'calc(50% - 24px)',
            display: 'none',
            '@bp2': {
              display: 'block',
            },
          }}
        >
          <PaymentResume
            description={data.messages.main}
            amount={data.amount}
          />
        </Box>
      </Flex>
    </Content>
  );
};

import { PaymentFlowAnalytics } from 'analytics/payment-flow-analytics';

import { StyledLink } from 'components/Link';

import { SectionGroup } from 'modules/checkout/components/Section/SectionGroup';
import { SectionText } from 'modules/checkout/components/Section/SectionText';

import { PaymentTermsProps } from './types';

export const PaymentTerms = ({ label, file }: PaymentTermsProps) => {
  const [first, second] = label.split('{link}');

  return (
    <SectionGroup>
      <SectionText>
        {first}
        <StyledLink
          href={file}
          title="Termos de Uso"
          target="_blank"
          onClick={PaymentFlowAnalytics.onClickSeeTerms}
        >
          Termos de Uso
        </StyledLink>{' '}
        {second}
      </SectionText>
    </SectionGroup>
  );
};

import { styled } from '@maistodos/design-system-web';

export const Header = styled('header', {
  height: 100,
  width: '100%',
  backgroundColor: '$header',
  paddingLeft: '$spacing24',
  paddingRight: '$spacing24',
  display: 'none',

  '@bp1': {
    display: 'flex',
    alignItems: 'center',
  },
});

export const HeaderLogo = styled('img', {
  height: '100%',
  marginLeft: '$auto',
  marginRight: '$auto',
});

import { Typography } from '@maistodos/design-system-web';

import { Section } from './styles';
import { ContentProps } from './types';

export const Content = ({ title, children }: ContentProps) => {
  return (
    <>
      <Typography
        weight="bold"
        variant={{
          '@initial': 'h6',
          '@bp1': 'h5',
        }}
        align={{
          '@initial': 'center',
          '@bp1': 'left',
        }}
        css={{
          color: '$neutral0',
          paddingTop: '$spacing24',
          paddingBottom: '$spacing24',

          '@bp1': {
            color: '$neutral999',
            paddingTop: '$spacing0',
            paddingBottom: '$spacing16',
          },
        }}
      >
        {title}
      </Typography>

      <Section>{children}</Section>
    </>
  );
};

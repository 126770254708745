import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Creditcard, Barcode, Pix } from '@maistodos/core-icons';
import { Flex, Tabs, TabsList } from '@maistodos/design-system-web';

import { PaymentFlowAnalytics } from 'analytics/payment-flow-analytics';

import { PaymentOption } from 'entities/PaymentType';

import { FormBankSlip } from 'modules/checkout/components/Form/FormBankSlip';
import { FormCard } from 'modules/checkout/components/Form/FormCard';
import { FormPix } from 'modules/checkout/components/Form/FormPix';
import { usePaymentTypes } from 'modules/checkout/hooks/usePaymentTypes';

import { PaymentButton, PaymentContent } from './styles';
import { PaymentOptionsProps } from './types';

export const PaymentOptions = ({
  id,
  amount,
  types,
  installments,
  labels,
  showSetDefaultCard,
}: PaymentOptionsProps) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<string>('');

  const { creditCard, debitCard, bankSlip, pix } = usePaymentTypes(types);
  const hasAnyCard = Boolean(creditCard) || Boolean(debitCard);

  useEffect(() => {
    if (types.length === 1) {
      setTab(types[0].type);
    }
  }, [types]);

  useEffect(() => {
    if (tab) {
      PaymentFlowAnalytics.onClickPaymentType(tab);
    }
  }, [tab]);

  return (
    <Tabs value={tab} onValueChange={setTab}>
      <TabsList>
        <Flex
          direction="row"
          css={{ gridColumnGap: '$spacing16', width: '50%' }}
        >
          {hasAnyCard && (
            <PaymentButton
              value={PaymentOption.CreditCard}
              data-testid={PaymentOption.CreditCard}
            >
              <Creditcard color="inherit" fontSize="30px" />
              {t('Cartão')}
            </PaymentButton>
          )}
          {bankSlip && (
            <PaymentButton
              value={PaymentOption.BankSlip}
              data-testid={PaymentOption.BankSlip}
            >
              <Barcode color="inherit" fontSize="30px" />
              Boleto
            </PaymentButton>
          )}
          {pix && (
            <PaymentButton
              value={PaymentOption.Pix}
              data-testid={PaymentOption.Pix}
            >
              <Pix color="inherit" fontSize="30px" />
              Pix
            </PaymentButton>
          )}
        </Flex>
      </TabsList>

      {hasAnyCard && (
        <PaymentContent value={PaymentOption.CreditCard}>
          <FormCard
            id={id}
            defaultCard={labels.defaultCard}
            showSetDefaultCard={showSetDefaultCard}
            creditCard={creditCard}
            debitCard={debitCard}
            installments={installments}
          />
        </PaymentContent>
      )}

      {bankSlip && (
        <PaymentContent value={PaymentOption.BankSlip}>
          <FormBankSlip id={id} amount={amount} paymentType={bankSlip} />
        </PaymentContent>
      )}

      {pix && (
        <PaymentContent value={PaymentOption.Pix}>
          <FormPix id={id} amount={amount} paymentType={pix} />
        </PaymentContent>
      )}
    </Tabs>
  );
};

import { styled } from '@maistodos/design-system-web';

export const Main = styled('main', {
  width: '100%',
  paddingTop: '$spacing0',
  paddingBottom: '$spacing0',
  marginLeft: '$auto',
  marginRight: '$auto',

  '@bp1': {
    maxWidth: 722,
    paddingTop: '$spacing32',
    paddingBottom: '$spacing32',
    paddingLeft: '$spacing16',
    paddingRight: '$spacing16',
  },
});

import { Box, styled } from '@maistodos/design-system-web';

export const Container = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$spacing32',
  color: '$neutral400',
});

export const IconBox = styled('span', {
  color: '$warning500',
});

export const Content = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$spacing16',
});

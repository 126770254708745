import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ChevronLeft } from '@maistodos/core-icons';
import {
  Typography,
  Accordion,
  AccordionContent,
  AccordionItem,
} from '@maistodos/design-system-web';

import { PaymentFlowAnalytics } from 'analytics/payment-flow-analytics';

import { formatLocaleCurrency } from 'utils/formatLocale';

import { SectionText } from 'modules/checkout/components/Section/SectionText';
import { SectionTitle } from 'modules/checkout/components/Section/SectionTitle';

import {
  CustomSectionGroup,
  CustomSeparator,
  DetailsContainer,
  Price,
  PriceTypography,
  AccordionTriggerSeeMore,
  AccordionContentSeeMore,
} from './styles';
import { PaymentResumeProps } from './types';

export const PaymentResume = ({ description, amount }: PaymentResumeProps) => {
  const { t, i18n } = useTranslation();
  const [option, setOption] = useState<string>('');

  const toggle = (newValue: string) => {
    PaymentFlowAnalytics.onClickSeeDetails(newValue);
    setOption(newValue);
  };

  return (
    <DetailsContainer>
      <CustomSectionGroup direction="column">
        <SectionTitle>{t('Resumo do pagamento')}</SectionTitle>
        <SectionText>{description}</SectionText>
      </CustomSectionGroup>

      <CustomSeparator position="top" />

      <Accordion
        type="single"
        value={option}
        onValueChange={toggle}
        collapsible
      >
        <AccordionItem value="resume">
          <AccordionTriggerSeeMore>
            Ver {option ? 'menos' : t('mais')}
            <ChevronLeft color="currentColor" fontSize="24px" />
          </AccordionTriggerSeeMore>

          <AccordionContent>
            <AccordionContentSeeMore direction="column">
              <Typography variant="medium" css={{ color: '$neutral400' }}>
                {t('Valor a pagar:')}
              </Typography>
              <Typography variant="medium" css={{ color: '$neutral600' }}>
                {formatLocaleCurrency(amount, i18n.language)}
              </Typography>
            </AccordionContentSeeMore>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <CustomSeparator position="bottom" />

      <Price alignItems="center" justifyContent="spaceBetween">
        <PriceTypography>{t('Total:')}</PriceTypography>
        <PriceTypography>
          {formatLocaleCurrency(amount, i18n.language)}
        </PriceTypography>
      </Price>
    </DetailsContainer>
  );
};

import axios from 'axios';

import { BASE_API } from 'config/environment';

export const baseApi = axios.create({
  baseURL: `${BASE_API}/v1`,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

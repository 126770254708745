import { useCallback, useState, useMemo, useEffect } from 'react';

import Cards, { Focused } from 'react-credit-cards';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Checkbox,
  Radio,
  SelectField,
  TextField,
} from '@maistodos/design-system-web';

import { PaymentFlowAnalytics } from 'analytics/payment-flow-analytics';

import { PaymentStatus } from 'entities/PaymentStatus';
import { PaymentOption } from 'entities/PaymentType';

import { useBrowserInfo } from 'hooks/useBrowserInfo';
import { useModal } from 'hooks/useModal';

import { useLink } from 'services/useLink';
import { usePay } from 'services/usePay';

import { formatLocaleCurrency } from 'utils/formatLocale';
import { onPreventSubmit } from 'utils/onPreventSubmit';

import { ModalError } from 'modules/checkout/components/ModalError';
import { SectionGroup } from 'modules/checkout/components/Section/SectionGroup';
import { SectionText } from 'modules/checkout/components/Section/SectionText';
import { SectionTitle } from 'modules/checkout/components/Section/SectionTitle';

import {
  formCreditCard,
  formDebitCard,
  masks,
  ONE_INSTALLMENT,
} from './constants';
import { Column, Form, Options } from './styles';
import { FormCardProps, FormData } from './types';
import { getRequiredFields } from './utils';

import 'react-credit-cards/es/styles-compiled.css';

export const FormCard = ({
  id,
  defaultCard,
  showSetDefaultCard,
  creditCard,
  debitCard,
  installments,
}: FormCardProps) => {
  const { t, i18n } = useTranslation();
  const browserInfo = useBrowserInfo();

  const [focused, setFocused] = useState<Focused | undefined>();
  const [issuer, setIssuer] = useState<string>('unknown');

  const {
    isOpen: isOpenErrorModal,
    open: openErrorModal,
    close: closeErrorModal,
  } = useModal();

  const { refetch } = useLink(id);
  const { mutate, isPending, data, error } = usePay(id);
  const required = getRequiredFields(t);

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    setValue,
  } = useForm<FormData>(creditCard ? formCreditCard : formDebitCard);

  const [card, number, expDate, holder, cvc] = useWatch({
    control,
    name: ['card', 'number', 'expDate', 'holder', 'cvc'],
  });

  const watchedInstallments = useWatch({
    control,
    name: 'installments',
  });

  const formattedInstallments = useMemo(() => {
    return installments.map((installment) => {
      const key = Object.keys(installment)[0];
      const { value } = installment[key];
      const price = parseFloat(value);

      return {
        key,
        price: formatLocaleCurrency(price, i18n.language),
      };
    });
  }, [i18n.language, installments]);

  const initialInstallment = useMemo<number>(() => {
    if (formattedInstallments.length === 0) {
      return ONE_INSTALLMENT;
    }

    return parseInt(formattedInstallments[0].key);
  }, [formattedInstallments]);

  const errorTitle = useMemo(() => {
    if (error?.response?.data?.needs_to_pay_with_max_installments?.length) {
      return error?.response?.data.needs_to_pay_with_max_installments[0];
    }

    return t('Não foi possível processar seu pagamento.');
  }, [error?.response?.data.needs_to_pay_with_max_installments, t]);

  const onChangeType = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target as typeof e.target & {
        value: PaymentOption;
      };

      PaymentFlowAnalytics.onClickCardType(target.value);

      if (target.value === PaymentOption.DebitCard) {
        setValue('installments', ONE_INSTALLMENT);
        return;
      }

      // Credit card
      setValue('installments', initialInstallment);
    },
    [initialInstallment, setValue]
  );

  const onFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    const target = e.target as typeof e.target & {
      name: Focused;
    };

    setFocused(target.name);
  }, []);

  const onSubmit = handleSubmit((data) => {
    PaymentFlowAnalytics.onClickPayment();

    const [month, year] = data.expDate.split('/');
    const paymentTypes = {
      [PaymentOption.CreditCard]: creditCard?.id || 0,
      [PaymentOption.DebitCard]: debitCard?.id || 0,
    };

    let three_ds2_data = undefined;
    if (card === PaymentOption.DebitCard) {
      three_ds2_data = {
        browserInfo: {
          acceptHeader: browserInfo?.acceptHeader,
          colorDepth: browserInfo?.colorDepth,
          language: browserInfo?.language,
          javaEnabled: browserInfo?.javaEnabled,
          screenHeight: browserInfo?.screenHeight,
          screenWidth: browserInfo?.screenWidth,
          userAgent: browserInfo?.userAgent,
          timeZoneOffset: browserInfo?.timeZoneOffset,
        },
      };
    }

    mutate(
      {
        payment_type: paymentTypes[data.card],
        credit_card: {
          is_default: data.isDefault,
          number: data.number.replace(/ /g, ''),
          exp_date: `20${year}-${month}`,
          cvv: data.cvc,
          holder: data.holder,
        },
        installments: data.installments,
        three_ds2_data,
      },
      {
        onSettled(response) {
          if (response?.status === PaymentStatus.Paid) {
            refetch();
            PaymentFlowAnalytics.onApprovedPayment(data.card);
            return;
          }

          openErrorModal();
        },
      }
    );
  });

  /**
   * Set the initial installment value if initial is different from "1".
   */
  useEffect(() => {
    if (initialInstallment === ONE_INSTALLMENT) return;
    setValue('installments', initialInstallment);
  }, [initialInstallment, setValue]);

  useEffect(() => {
    PaymentFlowAnalytics.onClickInstallments(watchedInstallments);
  }, [watchedInstallments]);

  return (
    <div>
      <Form
        method="post"
        onSubmit={isPending ? onPreventSubmit : onSubmit}
        noValidate
      >
        {/* Left side */}
        <Column direction="column">
          <Options>
            <Radio
              {...register('card', { onChange: onChangeType })}
              label="Crédito"
              disabled={!creditCard}
              value={PaymentOption.CreditCard}
            />
            <Radio
              {...register('card', { onChange: onChangeType })}
              label="Débito"
              disabled={!debitCard}
              value={PaymentOption.DebitCard}
            />
          </Options>

          <SectionGroup direction="column">
            <SectionTitle>{t('Dados do cartão')}</SectionTitle>
            <SectionText>{t('Informe os dados do seu cartão')}</SectionText>
          </SectionGroup>

          <TextField
            {...register('number', required.number)}
            onFocus={onFocus}
            label={t('Número do cartão')}
            placeholder={t('Digite o número do cartão')}
            mask={masks[issuer]?.number ?? masks.default.number}
            error={Boolean(errors?.number)}
            helperText={errors?.number?.message}
          />

          <Options>
            <TextField
              {...register('expDate', required.expDate)}
              onFocus={onFocus}
              label={t('Validade')}
              placeholder="MM/AA"
              mask="dd/dd"
              error={Boolean(errors?.expDate)}
              helperText={errors?.expDate?.message}
            />

            <TextField
              {...register('cvc', required.cvc)}
              onFocus={onFocus}
              label={t('CVV')}
              placeholder="123"
              mask={masks[issuer]?.cvc ?? masks.default.cvc}
              error={Boolean(errors?.cvc)}
              helperText={errors?.cvc?.message}
            />
          </Options>

          <TextField
            {...register('holder', required.holder)}
            onFocus={onFocus}
            label={t('Nome como no cartão')}
            placeholder={t('Digite seu nome como no cartão')}
            error={Boolean(errors?.holder)}
            helperText={errors?.holder?.message}
          />

          {card === PaymentOption.CreditCard && (
            <SelectField
              {...register('installments', required.installments)}
              label={t('Parcelas')}
              placeholder={initialInstallment.toString()}
              error={Boolean(errors?.installments)}
              helperText={errors?.installments?.message}
            >
              {formattedInstallments.map((installment) => (
                <option key={installment.key} value={installment.key}>
                  {installment.key}x de {installment.price}
                </option>
              ))}
            </SelectField>
          )}

          {showSetDefaultCard && (
            <Checkbox {...register('isDefault')} label={defaultCard} />
          )}
        </Column>

        {/* Right side */}
        <Column direction="column" justifyContent="flexEnd">
          <Box
            css={{
              display: 'none',
              '@bp2': {
                display: 'block',
              },
            }}
          >
            <Cards
              cvc={cvc}
              expiry={expDate}
              focused={focused}
              name={holder}
              number={number}
              placeholders={{ name: t('NOME') }}
              locale={{ valid: t('Valido até') }}
              callback={(e) => {
                setIssuer(e.issuer);
              }}
            />
          </Box>

          <Button
            type="submit"
            loading={
              isPending || (data && data.status !== PaymentStatus.Failure)
            }
            fluid
          >
            {t('Pagar')}
          </Button>
        </Column>
      </Form>

      <ModalError
        isOpen={isOpenErrorModal}
        onClose={closeErrorModal}
        title={errorTitle}
        description={t(
          'Verifique as informações do seu pagamento e tente novamente.'
        )}
      />
    </div>
  );
};

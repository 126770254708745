import { formatString } from '@maistodos/design-system-web';

import { Currency } from './types';

const currencies: Currency = {
  pt_BR: 'BRL',
  es_CO: 'COP',
  es_CL: 'CLP',
} as const;

const languages: Currency = {
  pt_BR: 'pt-BR',
  es_CO: 'es-CO',
  es_CL: 'es-CL',
} as const;

const getLocaleCurrency = (lang: string) => {
  if (lang in currencies) {
    return currencies[lang];
  }
  return currencies.pt_BR;
};

export const formatLocaleCurrency = (val: number, lang: string) => {
  return new Intl.NumberFormat(languages[lang], {
    style: 'currency',
    currency: getLocaleCurrency(lang),
  }).format(val);
};

export const formatLocaleCustomerDocument = (
  document: string,
  lang: string
) => {
  if (lang !== 'pt_BR') {
    return document;
  }

  if (document.length >= 14) {
    return formatString(document, 'dd.ddd.ddd/dddd-dd');
  }

  return formatString(document, 'ddd.ddd.ddd-dd');
};

export const isLanguageSupported = (lang: string | undefined) => {
  if (lang && Object.keys(languages).includes(lang)) {
    return lang;
  }
  return undefined;
};

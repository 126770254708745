import { styled } from '@maistodos/design-system-web';

export const Section = styled('section', {
  backgroundColor: '$neutral0',
  borderTopLeftRadius: '$large',
  borderTopRightRadius: '$large',
  paddingTop: '$spacing32',
  paddingBottom: '$spacing32',
  paddingLeft: '$spacing24',
  paddingRight: '$spacing24',

  '@bp1': {
    borderTopLeftRadius: '$medium',
    borderTopRightRadius: '$medium',
    borderBottomLeftRadius: '$medium',
    borderBottomRightRadius: '$medium',
    paddingLeft: '$spacing32',
    paddingRight: '$spacing32',
  },
});

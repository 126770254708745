import { styled } from '@maistodos/design-system-web';

export const AlertContainer = styled('div', {
  display: 'flex',
  alignItems: 'start',
  padding: '12px 16px',
  borderRadius: '$medium',
  fontSize: '$small',
  fontWeight: '$semibold',
  variants: {
    variant: {
      success: {
        backgroundColor: '#D1FAE5',
        color: '#065F46',
      },
      error: {
        backgroundColor: '#FEE2E2',
        color: '#991B1B',
      },
      warning: {
        backgroundColor: '#FEF3C7',
        color: '#92400E',
      },
      info: {
        backgroundColor: '#DBEAFE',
        color: '#1E40AF',
      },
    },
  },
  defaultVariants: {
    variant: 'info',
  },
});

export const IconWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '$spacing8',
  width: '24px',
  height: '24px',
});

export const AlertContent = styled('div', {
  display: 'flex',
  alignItems: 'start',
  flexDirection: 'column',
  flex: 1,
  gap: '$spacing4',
  paddingTop: '$spacing2',
});

export const AlertTitle = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: '$bold',
  fontSize: '$medium',
});

export const AlertMessage = styled('div', {
  flex: 1,
});

import { useMemo } from 'react';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Error404 } from 'modules/checkout/screens/Error404';

const Page = () => {
  const { t } = useTranslation();
  const title = useMemo<string>(() => t('Pagamento não encontrado'), [t]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Error404 />
    </>
  );
};

export default Page;

import { styled, Flex } from '@maistodos/design-system-web';

export const Form = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  gridGap: '$spacing24',

  '@bp2': {
    flexDirection: 'row',
  },
});

export const Options = styled(Flex, {
  gridColumnGap: '$spacing16',
});

export const Column = styled(Flex, {
  flex: 1,
  gridRowGap: '$spacing24',
});

export enum PaymentOption {
  BankSlip = 'bank_slip',
  CreditCard = 'credit_card',
  DebitCard = 'debit_card',
  Pix = 'pix',
}

export type PaymentType = {
  id: number;
  type: PaymentOption;
};

import { useMemo } from 'react';

import { LinkStatus } from 'entities/LinkStatus';

import { useLink } from 'services/useLink';

export const usePaymentCheck = (id: string, status: LinkStatus) => {
  const { data } = useLink(id, {
    notifyOnChangeProps: ['data'],
  });

  const isValid = useMemo(() => data?.status === status, [data, status]);

  return {
    isValid,
  };
};

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { baseApi } from 'libs/axios';

import {
  PaymentErrorResponse,
  PaymentParams,
  PaymentResponse,
} from 'services/usePay/types';

import { parseData } from 'utils/parseData';

export const usePayGenerate = (id: string, paymentType: number) => {
  return useQuery<
    PaymentResponse,
    AxiosError<PaymentErrorResponse>,
    PaymentResponse
  >({
    queryKey: ['usePayGenerate', id, paymentType],
    queryFn: async () => {
      const response = await baseApi
        .post<PaymentResponse>(`/links/${id}/pay/`, {
          payment_type: paymentType,
        } satisfies PaymentParams)
        .then(parseData);

      return response;
    },
  });
};

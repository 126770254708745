export const copyToClipboard = (value: string) => {
  const myTemporaryInputElement = document.createElement('input');
  myTemporaryInputElement.type = 'text';
  myTemporaryInputElement.value = value;

  document.body.appendChild(myTemporaryInputElement);

  myTemporaryInputElement.select();
  document.execCommand('Copy');

  document.body.removeChild(myTemporaryInputElement);
};

'use client';

import { createPortal } from 'react-dom';

import { Alert } from '@maistodos/core-icons';
import { Button, Typography } from '@maistodos/design-system-web';

import Modal from 'modules/checkout/components/Modal';
import {
  Container,
  Content,
  IconBox,
} from 'modules/checkout/components/ModalError/styles';
import { ModalErrorProps } from 'modules/checkout/components/ModalError/types';

export const ModalError = ({
  isOpen,
  title,
  description,
  onClose,
}: ModalErrorProps) => {
  return createPortal(
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        <Content>
          <IconBox>
            <Alert color="currentColor" fontSize="8rem" />
          </IconBox>
          {title && (
            <Typography
              variant="h6"
              as="h6"
              weight="bold"
              color="neutral600"
              align="center"
              css={{ margin: 0 }}
            >
              {title}
            </Typography>
          )}
          {description && (
            <Typography
              variant="large"
              as="p"
              color="neutral500"
              align="center"
            >
              {description}
            </Typography>
          )}
        </Content>
        <Button size="medium" variant="secondary" fluid onClick={onClose}>
          Fechar
        </Button>
      </Container>
    </Modal>,
    document.body
  );
};

import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { LinkStatus } from 'entities/LinkStatus';

import { useLink } from 'services/useLink';

const linkDestinyByStatus: Record<LinkStatus, string> = {
  [LinkStatus.Created]: '',
  [LinkStatus.Paid]: '/receipt',
  [LinkStatus.Refunded]: '/refunded',
};

export const useRedirectPaymentStatus = (linkId: string) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { data: link } = useLink(linkId, {
    notifyOnChangeProps: ['data'],
  });

  useEffect(() => {
    if (link) {
      const url = `/${linkId}${linkDestinyByStatus[link.status]}`;

      if (location.pathname !== url) {
        navigate(url, { replace: true });
      }
    }
  }, [link, linkId, location.pathname, navigate]);
};

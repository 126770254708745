import {
  Document,
  Font,
  // Image as PDFImage,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import {
  formatLocaleCustomerDocument,
  formatLocaleCurrency,
} from 'utils/formatLocale';

import { ReceiptPdfProps } from './types';

const styles = StyleSheet.create({
  body: {
    paddingVertical: 35,
    paddingHorizontal: 35,
    fontFamily: 'Nunito',
    // $neutral600
    color: '#434343',
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    textAlign: 'center',
  },
  group: {
    fontSize: 14,
    fontWeight: 700,
  },
  group2: {
    fontSize: 12,
    fontWeight: 400,
    // $neutral400
    color: '#989898',
  },
  text: {
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'justify',
  },
  separator: {
    height: 1,
    width: '100%',
    // $neutral300
    backgroundColor: '#D2D2D2',
  },

  spacing8: {
    marginBottom: 6,
  },
  spacing24: {
    marginBottom: 12,
  },

  image: {
    maxHeight: 80,
    width: 'auto',
    height: 'auto',
    margin: '0 auto',
  },
});

export const ReceiptPdf = ({ data }: ReceiptPdfProps) => {
  const { t, i18n } = useTranslation();

  return (
    <Document>
      <Page style={styles.body}>
        {/* @TODO: Waiting S3 to allow CORS requests */}
        {/* <PDFImage style={styles.image} src={data.theme.file_logo} /> */}

        <View style={styles.spacing24}>
          <Text style={styles.title}>{t('Comprovante de pagamento')}</Text>
        </View>

        <View style={styles.spacing24}>
          <View style={styles.spacing8}>
            <Text style={styles.group}>{t('Dados pessoais')}</Text>
          </View>
          <View style={styles.spacing8}>
            <Text style={styles.text}>{data.customer.name}</Text>
          </View>
          {!!data.customer.email && (
            <View style={styles.spacing8}>
              <Text style={styles.text}>E-mail: {data.customer.email}</Text>
            </View>
          )}
          {!!data.customer.document && (
            <Text style={styles.text}>
              {`${t('Documento')}: ${formatLocaleCustomerDocument(
                data.customer.document,
                i18n.language
              )}`}
            </Text>
          )}
        </View>

        <View style={styles.spacing24}>
          <View style={styles.spacing8}>
            <Text style={styles.group}>{t('Nome do emissor')}</Text>
          </View>
          <Text style={styles.text}>{data.company.name}</Text>
        </View>

        <View style={styles.spacing24}>
          <View style={styles.separator} />
        </View>

        <View style={styles.spacing24}>
          <View style={styles.spacing8}>
            <Text style={styles.group}>{t('Resumo do pagamento')}</Text>
          </View>
          <Text style={styles.text}>{data.messages.main}</Text>
        </View>

        <View style={styles.spacing24}>
          <View style={styles.spacing8}>
            <Text style={styles.group2}>{t('Valor total:')}</Text>
          </View>
          <Text style={styles.text}>
            {formatLocaleCurrency(data.amount_paid, i18n.language)}
          </Text>
        </View>

        <View style={styles.spacing24}>
          <View style={styles.spacing8}>
            <Text style={styles.group2}>{t('Valor pago:')}</Text>
          </View>
          <Text style={styles.text}>
            {formatLocaleCurrency(data.amount_paid, i18n.language)}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

// https://gist.github.com/karimnaaji/b6c9c9e819204113e9cabf290d580551
Font.register({
  family: 'Nunito',
  fontWeight: 400,
  src: 'https://fonts.gstatic.com/s/nunito/v8/ySZTeT3IuzJj0GK6uGpbBg.ttf',
});

Font.register({
  family: 'Nunito',
  fontWeight: 700,
  src: 'https://fonts.gstatic.com/s/nunito/v8/aEdlqgMuYbpe4U3TnqOQMA.ttf',
});

import { CheckCircle, Close, Info, Alert } from '@maistodos/core-icons';

export const alertVariants = {
  success: {
    icon: CheckCircle,
  },
  error: {
    icon: Close,
  },
  warning: {
    icon: Alert,
  },
  info: {
    icon: Info,
  },
};

import { useMemo } from 'react';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { useLinkId } from 'hooks/useLinkId';

import { Receipt } from 'modules/checkout/screens/Receipt';

const Page = () => {
  const { t } = useTranslation();
  const title = useMemo<string>(() => t('Comprovante de pagamento'), [t]);
  const id = useLinkId();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Receipt id={id} title={title} />
    </>
  );
};

export default Page;

import { styled } from '@maistodos/design-system-web';

export const Footer = styled('footer', {
  display: 'flex',
  flexDirection: 'column',
  gridRowGap: '$spacing4',
  textAlign: 'center',
  padding: '$spacing16',
  backgroundColor: '$neutral0',

  '@bp1': {
    backgroundColor: '$transparent',
  },
});

export const FooterContent = styled('div', {
  width: '100%',
  height: 49,
});

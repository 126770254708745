import { UseFormProps } from 'react-hook-form';

import { IS_DEV } from 'config/environment';

import { PaymentOption } from 'entities/PaymentType';

import { FormData } from './types';

export const masks: Record<string, { number: string; cvc: string }> = {
  amex: {
    number: 'dddd dddddd ddddd',
    cvc: 'dddd',
  },
  default: {
    number: 'dddd dddd dddd dddd',
    cvc: 'ddd',
  },
};

export const ONE_INSTALLMENT = 1;

export const formCreditCard: UseFormProps<FormData> = {
  defaultValues: {
    isDefault: false,
    card: PaymentOption.CreditCard,
    number: IS_DEV ? '5332 5659 3186 7188' : '',
    expDate: IS_DEV ? '12/27' : '',
    holder: IS_DEV ? 'Hélcio Franco' : '',
    cvc: IS_DEV ? '123' : '',
    installments: ONE_INSTALLMENT,
  },
};

export const formDebitCard: UseFormProps<FormData> = {
  defaultValues: {
    isDefault: false,
    card: PaymentOption.DebitCard,
    number: IS_DEV ? '5332 5659 3186 7188' : '',
    expDate: IS_DEV ? '12/27' : '',
    holder: IS_DEV ? 'Hélcio Franco' : '',
    cvc: IS_DEV ? '123' : '',
    installments: ONE_INSTALLMENT,
  },
};
